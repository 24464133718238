<template>
  <!-- 登录页 -->
  <div></div>
</template>

<script>
import { toLogin } from "@/api/login.js";
export default {
  name: "login",
  created() {
    const loading = this.$loading("正在跳转登录页面");
    // toLogin().then((res) => {
    //   console.log(res)
    // }).catch(() => {
    //   this.$alert('跳转登录页面失败!', '错误', {
    //     type: 'warning'
    //   });
    // }).finally(() => {
    //   loading.close();
    // });
    // 跳转云平台单点登录
    location.href = window.baseConfig.VUE_APP_SSO_LOGIN_PATH;
  },
};
</script>
